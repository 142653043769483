var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recognition-page-three" },
    [
      _c("PhotoGame", {
        attrs: {
          imgList: _vm.imgList,
          buttonList: _vm.buttonList,
          titleInfo: _vm.titleInfo,
        },
        on: { changeTheBgImg: _vm.changeTheBgImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }