<!--
 * @Author: your name
 * @Date: 2022-03-11 13:19:36
 * @LastEditTime: 2022-03-15 14:18:25
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson22\5_DragGameOne.vue
-->
<template>
  <div class="game-container">
    <DragGame
      :envelopeList="envelopeList"
      :mailboxList="mailboxList"
      :dragNum="dragNum"
      @randomOrder="handleRandomOrder"
      @socketRandom="handleSocketRandom"
    />
  </div>
</template>

<script>
import DragGame from "@/components/Course/GamePage/DragGame";
export default {
  data() {
    return {
      dragNum: 2,
      envelopeList: [
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-22-envelope-ji.svg"),
          isDispear: false,
          key: 1,
          index: 1,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-22-envelope-sui.svg"),
          isDispear: false,
          key: 2,
          index: 2,
        },
      ],
      mailboxList: [
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
          pinyin: "jǐ",
          hanzi: "几",
          value: 1,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
          pinyin: "suì",
          hanzi: "岁",
          value: 2,
        },
      ],
    };
  },
  components: {
    DragGame,
  },
  methods: {
    handleSocketRandom(value) {
      this.envelopeList = value;
    },
    handleRandomOrder() {
      this.envelopeList.sort(function () {
        return Math.random() - 0.5;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>