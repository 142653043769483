<!--
 * @Author: your name
 * @Date: 2022-03-11 13:19:36
 * @LastEditTime: 2022-03-15 16:31:34
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson22\4_RocketGame.vue
-->
<template>
  <div class="game-container">
    <RocketGame :bcgImg="bcgImg" />
  </div>
</template>

<script>
import RocketGame from "@/components/Course/GamePage/RocketGame";
export default {
  data() {
    return {
      bcgImg: require("@/assets/img/16-GAMES/G09-rocket/background-space-lesson-22.svg"),
    };
  },
  components: {
    RocketGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>